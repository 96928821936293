<template>
  <el-popover
    v-model="visible"
    popper-class="ccPopoverBox"
    trigger="manual"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- 头部 -->
    <div
      class="ccPopoverHeader"
      ref="ccPopoverHeader"
      :style="{ 'background-color': headerColor }"
    >
      <!-- 左侧图标和title -->
      <div>
        <svg class="icon headerIcon" aria-hidden="true">
          <use fill="ffffff" :href="iconName"></use>
        </svg>
        {{ title }}
      </div>
      <!-- 右侧取消图标 -->
      <svg class="icon cancelIcon" aria-hidden="true" @click="cancelpopper">
        <use fill="ffffff" href="#icon-a-Crosssign"></use>
      </svg>
    </div>
    <!-- 插槽内容 -->
    <div class="mainBox">
      <slot>{{ content }}</slot>
    </div>
    <!-- reference -->
    <template slot="reference">
      <slot name="reference"></slot>
    </template>
  </el-popover>
</template>
<script>
/**
 * 统一标准的提示弹窗组件
 * 1、props使用-隔开的形式传递，对驼峰写法支持不太好
 * 2、仅支持trigger为manual方式，默认就是manual,无需传trigger
 * 3、不支持自定义样式名称
 */
import { Popover } from "element-ui";
export default {
  // extends: Popover,
  name: "CCPopover",
  inheritAttrs: false,
  components: {
    "el-popover": Popover,
  },
  props: {
    content: String,
    title: String,
    // v-model,必须使用.sync传入
    modelData: Boolean,
    // 头部图标名称
    iconName: {
      type: String,
      default: "#icon-yanzhengbaocuo",
    },
    // 头部背景颜色
    headerColor: {
      type: String,
      default: "#D62940",
    },
  },
  data() {
    return {
      // 控制弹窗显隐
      visible: false,
    };
  },
  watch: {
    modelData: {
      handler: function (newVal) {
        this.visible = newVal;
        if (newVal) {
          this.$nextTick(() => {
            let popperDom = document.getElementsByClassName("popper__arrow")[0];
            if (popperDom) {
              // 根据弹窗位置，设置三角形背景色
              let top = parseFloat(popperDom.style.top);
              // 头部的高度
              let headHeight = this.$refs.ccPopoverHeader.clientHeight;
              //   获取当前弹窗的位置，如果页面位置大小不支持弹窗展示，弹窗会展示在相反的位置。（弹窗位置和this.placement设置的不一定相同）
              let afterDom = window.getComputedStyle(popperDom, ":after");
              let placeAry = [
                "border-right-color",
                "border-left-color",
                "border-top-color",
                "border-bottom-color",
              ];
              // 当前弹窗的位置
              let placement = "";
              placeAry.forEach((item) => {
                if (afterDom[item] && afterDom[item] !== "rgba(0, 0, 0, 0)") {
                  placement = item;
                }
              });
              if (placement === "border-bottom-color" || top < headHeight) {
                // 当三角形在顶部或者距离顶部不大于头部高度时，改变三角形的伪元素背景色
                popperDom.style[placement] = this.headerColor;
              } else {
                popperDom.style[placement] = "#ffffff";
              }
            }
          });
        }
      },
      immdeiate: true,
    },
  },
  methods: {
    cancelpopper() {
      this.visible = false;
      this.$emit("update:modelData", false);
    },
  },
};
</script>
<style lang="scss">
.ccPopoverBox {
  padding: 0px;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 18%);
  border: none !important;
}
.ccPopoverBox[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: inherit;
}
.ccPopoverBox[x-placement^="top"] .popper__arrow::after {
  border-bottom-color: inherit;
}
.ccPopoverBox[x-placement^="right"] .popper__arrow::after {
  border-right-color: inherit;
  border-left-color: inherit;
}
.ccPopoverBox[x-placement^="left"] .popper__arrow::after {
  border-right-color: inherit;
  border-left-color: inherit;
}
</style>
<style lang="scss" scoped>
.ccPopoverHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 4px 4px 0 0;
  .icon {
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  .headerIcon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }
  .cancelIcon {
    cursor: pointer;
    width: 10px;
    height: 10px;
  }
}
.mainBox {
  padding: 8px 16px 12px;
}
</style>